<template>
  <div>
    <v-row>
      <v-col cols="6" v-for="extModID in alwaysSelectedExtMods" :key="extModID">
        <strong>{{ extModByID(extModID).name }}</strong>
        <ul>
          <li
            v-for="item in includesList(extModID)"
            :key="item">
            {{ item }}
          </li>
        </ul>
        <ul>
          <li
            v-for="id in extModByID(extModID).applications.included"
            :key="id">
            {{ applicationByID(id).name }} {{ interfaceData.app }}
          </li>
          <li
            v-for="id in extModByID(extModID).accessories.included"
            :key="id">
            {{ accessoryByID(id).name }}
          </li>
        </ul>
      </v-col>

      <v-col cols="6" v-for="extModID in selectedExtensionsModulesModified" :key="extModID">
        <strong data-testid="extModName">{{ extModByID(extModID).name }} </strong><strong class="accent--text">({{ numberFormatter.format(ExtModSelectedAsSys === extModByID(extModID).id ? extModByID(extModID).fullPriceSys : extModByID(extModID).fullPriceExt) }})</strong>
        <ul>
          <li
            v-for="item in includesList(extModID)"
            :key="item">
            {{ item }}
          </li>
        </ul>
        <ul>
          <li
            v-for="id in extModByID(extModID).applications.included"
            :key="id">
            {{ applicationByID(id).name }} {{ interfaceData.app }} <strong class="accent--text" v-if="applicationByID(id).fullPrice">({{ numberFormatter.format(applicationByID(id).fullPrice) }})</strong>
          </li>
          <li
            v-for="id in extModByID(extModID).accessories.included"
            :key="id">
            {{ accessoryByID(id).name }} <strong class="accent--text">({{ numberFormatter.format(accessoryByID(id).fullPrice) }})</strong>
          </li>
        </ul>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" v-if="additionalApps.length > 0">
        <strong>{{ interfaceData.additionalApps }}</strong>
        <ul>
          <li
            v-for="id in additionalApps"
            :key="id">
            {{ applicationByID(id).name }} {{ interfaceData.app }} <strong class="accent--text">({{ numberFormatter.format(applicationByID(id).fullPrice) }})</strong>
          </li>
        </ul>
      </v-col>
      <v-col cols="6" v-if="additionalAccessories.length > 0">
        <strong>{{ interfaceData.additionalAccessories }}</strong>
        <div>
          <ul>
            <li
              v-for="id in additionalAccessories"
              :key="id">
              {{ accessoryByID(id).name }} <strong class="accent--text">({{ numberFormatter.format(accessoryByID(id).fullPrice) }})</strong>
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" v-if="additionalConnectivity.length > 0 && partnerURL === 'fr'">
        <strong>{{ interfaceData.additionalConnectivity }}</strong>
        <div>
          <ul>
            <li
              v-for="(con, key) in additionalConnectivity"
              :key="key">
              {{ connectivityByID(con).name }} <strong class="accent--text">({{ numberFormatter.format(connectivityByID(con).fullPrice) }})</strong>
            </li>
          </ul>
        </div>
      </v-col>
      <v-col cols="6">
        <strong>{{ interfaceData.layout }}</strong>
        <ul>
          <li>
            {{ layoutByID(selectedLayout).name }}
          </li>
        </ul>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CurrentSelectionList',
  props: {
    fieldOfCareData: Array
  },
  components: {
  },
  computed: {
    ...mapGetters([
      'partnerURL',
      'interfaceData',
      'ExtModSelectedAsSys',
      'extModByID',
      'applicationByID',
      'layoutByID',
      'accessoryByID',
      'connectivityByID',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedLayout',
      'preselectedApplications',
      'selectedAccessories',
      'preselectedAccessories',
      'selectedConnectivity',
      'preselectedConnectivity',
      'numberFormatter'
    ]),
    selectedExtensionsModulesModified () {
      if (this.selectedExtensionsModules.includes('TBISYSCS4') && this.selectedExtensionsModules.includes('ABISYSCS4')) {
        // remove TBISYSCS4
        // add TBPSYSCS4
        const modifiedSelected = this.removeItemsFromArray(this.selectedExtensionsModules, ['TBISYSCS4'])
        modifiedSelected.push('TBPSYSCS4')
        return modifiedSelected
      }
      return this.selectedExtensionsModules
    },
    additionalApps () {
      return this.selectedApplications.filter((el) => {
        return !this.preselectedApplications.includes(el)
      })
    },
    additionalAccessories () {
      return this.selectedAccessories.filter((el) => {
        return !this.preselectedAccessories.includes(el)
      })
    },
    additionalConnectivity () {
      const conn = []
      if (this.preselectedConnectivity !== '') {
        conn.push(this.preselectedConnectivity)
      }
      if (this.selectedConnectivity.length > 0) {
        conn.push(this.selectedConnectivity[0])
      }
      return conn
    },
    alwaysSelectedExtMods () {
      return ['MTABSYSW']
    }
  },
  methods: {
    extNiceName (id) {
      const obj = this.extModByID(id)
      return obj.name
    },
    appNiceName (id) {
      const obj = this.applicationByID(id)
      return obj.name
    },
    accNiceName (id) {
      const obj = this.accessoryByID(id)
      return obj.name
    },
    includesList (id) {
      const obj = this.extModByID(id)
      return obj.includesList
    },
    imgPathExtMod: path => require('@/assets/extModules/interactive/' + path),
    imgPathApps: path => require('@/assets/apps/visual/' + path),
    imgPathAccs: path => require('@/assets/accessories/visual/' + path),
    imgPathConn (path) {
      if (path) {
        return require('@/assets/connectivity/visual/' + path)
      }
      return require('@/assets/placeholder.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
$imgw: 70px;

.m-mw-area {
  max-width: 190px;

  &.m-mw-area-lg {
    max-width: 330px;
  }
}

.m-block {
  border: 1px solid var(--v-secondary-lighten5);
  border-radius: 1rem;
  margin-bottom: 1rem;

  .title {
    padding-bottom: 0;
  }
}

ul {
  vertical-align: top;
}

.m-icon-wrap {
  max-width: $imgw;
  vertical-align: top;
  margin-bottom: 3px;

  .caption {
    line-height: 1.2;
    margin-top: 3px;
  }
}

.img {
  border: 2px solid var(--v-secondary-lighten5);
  border-radius: 50%;
  max-width: $imgw;
}
</style>
