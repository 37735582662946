<template>
  <div class="c-navigation text-center pa-6" :class="{ 'large-screens': $vuetify.breakpoint.mdAndUp }" ref="mainNavigation">
    <div v-show="!isLastStep">
      <v-btn data-cy-back rounded depressed color="accent" class="mx-3" :disabled="backBtnDisabled" @click="prevStep"><v-icon left>mdi-arrow-left</v-icon> {{ interfaceData.back }}</v-btn>
      <v-btn data-cy-next rounded depressed color="accent" class="mx-3" :disabled="nextBtnDisabled" @click="nextStep">{{ interfaceData.next }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
      <div class="mt-5">
        <v-btn data-cy-startover rounded depressed color="white" class="mx-2" @click="reset">{{ interfaceData.startOver }}</v-btn>
        <v-btn v-if="partnerURL === 'fr'" data-cy-summary rounded depressed color="white" class="mx-2" :disabled="summaryBtnDisabled" @click="summary">{{ interfaceData.summary}}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Navigation',
  computed: {
    ...mapGetters([
      'interfaceData',
      'partnerURL',
      'activeStep',
      'isLastStep',
      'steps',
      'selectedExtensionsModules',
      'selectedApplications'
    ]),
    allSteps () {
      return this.steps.length
    },
    backBtnDisabled () {
      return this.activeStep < 1
    },
    nextBtnDisabled () {
      return this.activeStep >= (this.allSteps - 1) || this.selectedExtensionsModules.length < 1
    },
    summaryBtnDisabled () {
      return this.selectedExtensionsModules.length < 1
    }
  },
  created () {
    this.updateNavHeight = this.debounce(this.updateNavHeight)
  },
  mounted () {
    this.updateNavHeight()
  },
  updated () {
    this.updateNavHeight()
  },
  methods: {
    prevStep () {
      this.trackEvent('Previous', 'ButtonClick')
      this.$store.commit('PREV_STEP')
      this.$vuetify.goTo(0)
    },
    nextStep () {
      this.trackEvent('Next', 'ButtonClick')
      this.$store.commit('NEXT_STEP')
      if (this.isLastStep) {
        this.$store.commit('SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP', 0)
      }
      this.$vuetify.goTo(0)
    },
    reset () {
      this.$store.dispatch('NAV_RESET_STATE', this.partnerURL)
      this.trackEvent('Reset', 'ButtonClick')
    },
    summary () {
      this.$store.commit('CHANGE_STEP', this.allSteps - 1)
    },
    updateNavHeight () {
      this.$nextTick(() => {
        if (!this.$vuetify.breakpoint.mdAndUp) {
          this.$store.commit('SET_NAV_HEIGHT', 'auto')
          return
        }
        const h = this.$refs.mainNavigation.clientHeight
        this.$store.commit('SET_NAV_HEIGHT', h)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-navigation {
  &.large-screens {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
</style>
