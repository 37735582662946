import fieldOfCareData from '@/data/MESI/fields.json'
import mixins from '@/mixins.js'

const CHANGE_FIELD = (context, fieldID) => {
  context.commit('RESET_STATE')
  context.commit('SET_INTERFACE_TEXTS', fieldID[1])
  context.commit('GENERATE_BY_ID_OBJECTS')
  context.commit('SET_DEFAULT_MTAB_PRICE')
  context.commit('UPDATE_SELECTED_FIELD', fieldID[0])

  const selectedField = mixins.getObjectWithIDFromArr(fieldOfCareData, fieldID[0])

  const extModsArray = selectedField.extensionsModules.included

  if (!extModsArray || extModsArray.length < 1) {
    return
  }
  for (const extMod of extModsArray) {
    context.commit('TOGGLE_EXTENSION_MODULE', extMod)
  }
}

const ADJUST_ABI_TBI = context => {
  context.commit('ADJUST_ABI_TBI')
  context.commit('SYNC_DATA')
}

const READJUST_ABI_TBI = context => {
  context.commit('READJUST_ABI_TBI')
  context.commit('SYNC_DATA')
}

const ADJUST_VITALS_PACKAGE = context => {
  context.commit('ADJUST_VITALS_PACKAGE')
  context.commit('SYNC_DATA')
}

const TOGGLE_EXTENSION_MODULE = (context, extModID, addOnly = false) => {
  context.commit('TOGGLE_EXTENSION_MODULE', extModID, addOnly)
  context.commit('SYNC_DATA')
}

const TOGGLE_APPLICATION = (context, appID) => {
  context.commit('TOGGLE_APPLICATION', appID)
  context.commit('SYNC_DATA')
}

const TOGGLE_ACCESSORY = (context, accID) => {
  context.commit('TOGGLE_ACCESSORY', accID)
  context.commit('SYNC_DATA')
}

const TOGGLE_CONNECTIVITY = (context, conID) => {
  context.commit('SELECT_CONNECTIVITY', conID)
  context.commit('SYNC_DATA')
}

const SHOW_DIALOG_THANK_YOU = context => {
  context.commit('CLOSE_ALL_DIALOGS')
  context.commit('SHOW_DIALOG_THANK_YOU')
}

const SHOW_GENERIC_DIALOG = (context, content) => {
  context.commit('CLOSE_ALL_DIALOGS')
  context.commit('SHOW_GENERIC_DIALOG', content)
}

const SET_DATA_FROM_CODE = ({ commit, state }, dataFromCode) => {
  commit('SHOW_GENERIC_DIALOG', { text: `${state.interfaceData.loadingPart1} <br />${state.interfaceData.loadingPart2} ...`, loading: true })
  // console.warn('CODE DATA: ', dataFromCode)

  for (const id of dataFromCode[0].selected.extensionsModules) {
    commit('TOGGLE_EXTENSION_MODULE', id, true)
    commit('SYNC_DATA')
  }

  for (const id of dataFromCode[0].selected.applications) {
    commit('TOGGLE_APPLICATION', id, true)
    commit('SYNC_DATA')
  }

  for (const id of dataFromCode[0].selected.accessories) {
    commit('TOGGLE_ACCESSORY', id, true)
    commit('SYNC_DATA')
  }

  for (const id of dataFromCode[0].selected.layouts) {
    commit('SET_LAYOUT', id)
    commit('SYNC_DATA')
  }

  commit('CHANGE_STEP', 'last')
  commit('COMPLETE_STEPS_ON_SHARE')

  const tm = setTimeout(() => {
    commit('CLOSE_GENERIC_DIALOG')
    if (dataFromCode[1] === null) {
      commit('SHOW_LOGIN_DIALOG')
    }
    clearTimeout(tm)
  }, 2000)
}

const INIT_DATA = (context) => {
  context.commit('GENERATE_BY_ID_OBJECTS')
  context.commit('SET_DEFAULT_MTAB_PRICE')
}

const NAV_RESET_STATE = (context, partnerURL) => {
  context.commit('RESET_STATE')
  context.commit('SET_INTERFACE_TEXTS', partnerURL)
  if (partnerURL === 'MESI') {
    window.location.href = '/'
  } else {
    window.location.href = '/' + partnerURL
  }
}

export default {
  CHANGE_FIELD,
  ADJUST_ABI_TBI,
  READJUST_ABI_TBI,
  ADJUST_VITALS_PACKAGE,
  TOGGLE_EXTENSION_MODULE,
  TOGGLE_APPLICATION,
  TOGGLE_ACCESSORY,
  TOGGLE_CONNECTIVITY,
  SHOW_DIALOG_THANK_YOU,
  SHOW_GENERIC_DIALOG,
  SET_DATA_FROM_CODE,
  INIT_DATA,
  NAV_RESET_STATE
}
