<template>
  <div class="visual-block-step step-3-visual-block pa-6 mt-8 mb-12" :class="{ 'active-step' : isActiveStep }" ref="Connectivity">

    <div class="pl-1 mb-2 title text-uppercase">{{ interfaceData.included }}</div>
    <div class="ma-n3 d-flex flex-wrap tr-group-wrap">
      <transition-group
          name="apps-wrap"
      >
        <div
          :key="1"
          class="d-inline-block img-wrap ma-3 app-item"
          transition="slide-y-transition"
        >
          <v-img :src="require('@/assets/connectivity/interactive/mTABLET-mRECORDS.png')" class="d-inline-block img" :lazy-src="lazyImgSrc" alt="mRECORDS software" title="mRECORDS software" contain>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </transition-group>
    </div>

    <div v-if="Object.keys(selected).length > 0" class="pl-1 mb-2 mt-4 title text-uppercase">{{ interfaceData.additional }}</div>
    <div v-if="Object.keys(selected).length > 0" class="ma-n3 d-flex flex-wrap tr-group-wrap">
      <transition-group
          name="apps-wrap"
      >
        <div
          :key="selected.id"
          class="d-inline-block img-wrap ma-3 app-item"
          transition="slide-y-transition"
        >
          <v-img :src="imgPath(selectedConnectivity[0])" class="d-inline-block img" :lazy-src="lazyImgSrc" :alt="selected.name" :title="selected.name" contain>
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'connectivityVisual',
  props: {
    stepIndex: Number,
    systemData: Object,
    isActiveStep: Boolean
  },
  computed: {
    lazyImgSrc () {
      return require('@/assets/placeholder.jpg')
    },
    ...mapGetters([
      'interfaceData',
      'steps',
      'activeStep',
      'selectedConnectivity',
      'connectivityByID'
    ]),
    componentName () {
      return this.steps[this.stepIndex].componentName
    },
    selected () {
      return this.connectivityByID(this.selectedConnectivity[0])
    }
  },
  mounted () {
    this.$root.$refs[this.componentName] = {
      ref: this.$refs[this.componentName],
      index: this.stepIndex
    }
  },
  methods: {
    imgPath (id) {
      const obj = this.getDataObj(id)
      if (typeof obj === 'undefined') {
        console.warn('MISSING connectivity PHOTO for ' + id, ' : ', obj)
        return ''
      }
      if (obj.img) {
        // return require('@/assets/placeholder.jpg')
        return require('@/assets/connectivity/visual/' + obj.img)
      }
      return require('@/assets/placeholder.jpg')
    },
    getDataObj (id = '') {
      return this.$store.getters.connectivityByID(id)
    }
  }
}
</script>

<style lang="scss" scoped>
$transitionSpeed: 1s;

.tr-group-wrap {
  position: relative;
  overflow: hidden;
}

.app-item {
  transition: all 1s;
}
.apps-wrap-enter, .apps-wrap-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.apps-wrap-leave-active {
  position: absolute;
}

.img-wrap {
  position: relative;

  .img {
    max-width: 100px;
    border: 2px solid var(--v-secondary-lighten5);
    border-radius: 50%;
    // so non square images are same width/height too
    height: 0;
    padding-bottom: 100%;
  }
}
</style>
