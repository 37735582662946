<template>
  <div class="visual-block px-md-5 ml-md-auto" id="visual-block" :style="visualBlockStyle" :class="visualBlockClass" ref="visualBlock">
    <div class="v-line" v-show="isDesktop() && !isLastStep">
      <div class="activeLine" :style="activeLineStyle"></div>
    </div>
    <div v-for="(step, stepIndx) in steps" :key="stepIndx">
      <transition name="slide-fade">
        <component
          v-show="showStep(stepIndx)"
          v-bind:is="stepComponentName(stepIndx)"
          :key="stepIndx"
          :systemData="systemData"
          :step-index="stepIndx"
          :field-of-care-data="fieldOfCareData"
          :is-active-step="isStepActive(stepIndx)"
          :ref="'step'+stepIndx"
        />
      </transition>
    </div>
    <div v-show="lastStepHeight && isDesktop()" class="mouse-icon-nav-indicator bg--text text--darken-2">
      <v-icon color="rgba(0,0,0,0.25)" class="arr-up">mdi-chevron-up</v-icon>
      <v-icon color="rgba(0,0,0,0.25)" x-large>mdi-mouse</v-icon> {{ interfaceData.scroll }}
      <v-icon color="rgba(0,0,0,0.25)" class="arr-down">mdi-chevron-down</v-icon>
    </div>
  </div>
</template>

<script>
/**
 * How to make these imports dynamic based on this.steps ?
 * Also registering components below in components: {}
 */
import ExtModules from '@/components/visualSteps/ExtModules.vue'
import Apps from '@/components/visualSteps/Apps.vue'
import Layout from '@/components/visualSteps/Layout.vue'
import Accessories from '@/components/visualSteps/Accessories.vue'
import Connectivity from '@/components/visualSteps/Connectivity.vue'
import Summary from '@/components/visualSteps/Summary.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'VisualBlock',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    ExtModules, Apps, Layout, Accessories, Connectivity, Summary
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'steps',
      'activeStep',
      'isLastStep',
      'visualLineHeight',
      'visualBlockNegativeMargin'
    ]),
    visualBlockStyle () {
      return { marginTop: '-' + this.visualBlockNegativeMargin + 'px' }
    },
    visualBlockClass () {
      return {
        'large-screens': this.isDesktop(),
        lastStep: this.isLastStep
      }
    },
    activeLineStyle () {
      return {
        height: this.visualLineHeight + 'px'
      }
    },
    lastStepHeight () {
      const element = this.$refs.visualBlock
      const lastStepHeight = this.steps[4].visualStepHeight
      if (element && lastStepHeight) {
        return this.$refs.visualBlock.offsetHeight < this.steps[4].visualStepHeight
      } else {
        return false
      }
    }
  },
  methods: {
    isStepActive (stepIndex) {
      return this.activeStep === stepIndex
    },
    stepComponentName (indx) {
      return this.steps[indx].componentName
    },
    showStep (indx) {
      if (this.isLastStep) {
        return indx === this.activeStep
      } else {
        return indx <= this.activeStep
      }
    }
  }
}
</script>

<style scoped lang="scss">
$lineWidth: 12px;
$transitionSpeed: 0.3s;

.visual-block {
  position: relative;
  margin-top: 0;
  transition: $transitionSpeed margin ease-in-out;
  overflow-x: hidden;

  &.large-screens {
    max-width: 800px;
    width: 100%;

    &.lastStep {
      max-height: 100vh;
      overflow: hidden scroll;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none;  /* IE 10+ */
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent; /* Chrome/Safari/Webkit */
      }

    }
  }

  .v-line {
    position: fixed;
    top: 0;
    width: $lineWidth;
    bottom: 0;
    left: 0;
    height: 100%;
    background: var(--v-bg-base);

    .activeLine {
      position: absolute;
      width: $lineWidth;
      left: 0;
      background: var(--v-accent-base);
      top: 50%;
      transform: translateY(-50%);
      transition: $transitionSpeed height ease-in-out;
    }
  }
}

.active-step {
  position: relative;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all $transitionSpeed ease-in-out;
}
.slide-fade-leave-active {
  transition: none;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

.mouse-icon-nav-indicator {
  position: fixed;
  text-align: center;
  bottom: 30px;
  left: 25%;
  transform: translateX(-50%);

  .arr-up, .arr-down {
    position: absolute;
    left: 8px;
  }

  .arr-up {
    top: -20px;
  }

  .arr-down {
    bottom: -20px;
  }
}
</style>
