export const getDefaultState = () => {
  const formURL = process.env.NODE_ENV === 'development' ? 'https://configurator.my/dist/inquiry' : 'https://configurator-p.mesimedical.com/inquiry'
  const formURLSubscription = process.env.NODE_ENV === 'development' ? 'https://configurator.my/dist/subscription' : 'https://configurator-p.mesimedical.com/subscription'
  const apiRegister = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/customer/register' : 'https://configurator-api-dev.mesimedical.com/api/customer/register'
  const apiReferal = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/customer/referal' : 'https://configurator-api-dev.mesimedical.com/api/customer/referal'
  const apiLogin = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/customer/login' : 'https://configurator-api-dev.mesimedical.com/api/customer/login'
  const apiStore = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/customer/store' : 'https://configurator-api-dev.mesimedical.com/api/customer/store'
  const apiUpdate = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/customer/update' : 'https://configurator-api-dev.mesimedical.com/api/customer/update'
  const apiPDF = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:8000/api/pdf' : 'https://configurator-api-dev.mesimedical.com/api/pdf'

  return {
    partnerURL: 'MESI',
    partnerLogo: '',
    isDistributer: false,
    referredByDistributer: '',
    APIURL_REGISTER: apiRegister,
    APIURL_REFERAL: apiReferal,
    APIURL_LOGIN: apiLogin,
    APIURL_STORE: apiStore,
    APIURL_UPDATE: apiUpdate,
    APIURL_PDF: apiPDF,
    // APIURL: 'https://configurator-p.mesimedical.com/api/index.php',
    FORMURL: formURL,
    FORMURL_SUBSCRIPTION: formURLSubscription,
    activeStep: 0,
    selectedField: null,
    defaultPriceMTAB: 19,
    fullPriceMTAB: 0,
    mTabIncludedApps: ['MTABAPPPHOTO'],
    ExtModSelectedAsSys: '',
    priceFull: 0,
    price: 0,
    priceGreenPackage: 0,
    pricePremiumPackage: 0,
    pricesAfterMtab: {
      price: 0,
      priceGreenPackage: 0,
      pricePremiumPackage: 0
    },
    numberFormatter: {},
    appHeight: null,
    navigationHeight: 140,
    visualBlockKey: 'vbk',
    visualBlockVisibleMobile: false,
    visualBlockNegativeMargin: 0,
    visualLineHeight: 0,
    configCode: '',
    configURL: '',
    configCodeSeparator: '-',
    extensionsModulesByID: {},
    applicationsByID: {},
    layoutsByID: {},
    accessoriesByID: {},
    connectivityByID: {},
    selected: {
      extensionsModules: [],
      applications: [],
      layout: 'TABLETOP',
      accessories: [],
      connectivity: []
    },
    modifiedSelectedExtMods: [],
    preselected: {
      applications: [],
      accessories: [],
      accessoriesActive: [],
      connectivity: ''
    },
    allowed: {
      applications: [],
      accessories: []
    },
    disabled: {
      extensionsModules: [],
      layouts: ['TROLLEYECG']
    },
    dialogs: {
      selectionDisabled: false,
      loginDialog: false,
      disabledItemsBySelectingAnother: [],
      generic: false,
      reset: false,
      selectPackage: false,
      sendInquiry: false,
      share: false,
      thankYou: false,
      genericDialogContent: '',
      genericDialogLoading: false
    },
    interfaceData: {},
    allExtModData: {},
    allAppsData: {},
    allLayoutsData: {},
    allAccessoriesData: {},
    allConnectivityData: {},
    steps: [
      {
        componentName: 'ExtModules',
        name: 'Measurements',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/extModules/interactive/',
        assetsVisualPath: '@/assets/extModules/visual/'
      },
      {
        componentName: 'Apps',
        name: 'Apps',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/apps/interactive/',
        assetsVisualPath: '@/assets/apps/visual/'
      },
      {
        componentName: 'Layout',
        name: 'Layouts',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/layout/interactive/',
        assetsVisualPath: '@/assets/layout/visual/'
      },
      {
        componentName: 'Accessories',
        name: 'Accessories',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/accessories/interactive/',
        assetsVisualPath: '@/assets/accessories/visual/'
      },
      {
        componentName: 'Connectivity',
        name: 'Connectivité',
        complete: false,
        visualStepHeight: null,
        assetsInteractivePath: '@/assets/connectivity/interactive/',
        assetsVisualPath: '@/assets/connectivity/visual/'
      },
      {
        componentName: 'Summary',
        name: 'Summary',
        complete: false,
        visualStepHeight: null
      }
    ]
  }
}
