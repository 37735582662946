<template>
  <div class="ctas-wrap">
    <v-row class="my-5">
      <v-col cols="12" sm="6" class="align-center d-flex">
        {{ interfaceData.inquiryActions.sendInquiryText }}
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn data-cy-send-inquiry-btn color="accent" block x-large rounded outlined depressed class="subtitle-1 flex-grow-0" @click="sendInquiryClick">{{ interfaceData.inquiryActions.sendInquiry }}</v-btn>
        <SendInquiryDialog :show="isSendInquiryDialogVisible" />
      </v-col>
      <!-- <v-col cols="12" sm="6" class="mt-3 my-sm-3 align-center d-flex">
        {{ interfaceData.inquiryActions.downloadPDFText }}
      </v-col>
      <v-col cols="12" sm="6" class="mb-3 my-sm-3">
        <v-btn data-cy-send-inquiry-btn color="accent" block x-large rounded outlined depressed class="subtitle-1 flex-grow-0" @click="generatePDF">{{ interfaceData.inquiryActions.downloadPDF }}</v-btn>
      </v-col> -->
    </v-row>
    <ResetDialog />
  </div>
</template>

<script>
import SendInquiryDialog from '@/components/dialogs/SendInquiryDialog.vue'
import ResetDialog from '@/components/dialogs/ResetDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InquiryActions',
  props: {},
  components: {
    SendInquiryDialog, ResetDialog
  },
  computed: {
    ...mapGetters([
      'partnerURL',
      'priceFull',
      'interfaceData',
      'isResetDialogVisible',
      'isSelectPackageDialogVisible',
      'isSendInquiryDialogVisible',
      'visualBlockVisibleMobile',
      'FORMURL',
      'configURL',
      'selectedExtensionsModules',
      'selectedApplications',
      'selectedLayout',
      'selectedAccessories',
      'selectedObject',
      'APIURL_UPDATE',
      'APIURL_STORE',
      'APIURL_PDF',
      'extModByID',
      'applicationByID',
      'accessoryByID',
      'layoutByID',
      'modifiedSelectedExtMods',
      'ExtModSelectedAsSys',
      'numberFormatter'
    ])
  },
  methods: {
    openShareDialog () {
      this.trackEvent('Share', 'ButtonClick')
      this.$store.commit('SHOW_SHARE_DIALOG')
    },
    generatePDF () {
      const date = new Date()
      const dateToday = ('0' + date.getDate()).slice(-2) + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        date.getFullYear()
      const filename = 'MESI mTABLET Configurator ' + dateToday
      const data = {
        mTablet: this.extModByID('MTABSYSW'),
        includedApps: this.getItemsData(['MTABAPPPHOTO'], 'applicationByID'),
        extModules: this.getItemsData(this.modifiedSelectedExtMods, 'extModByID'),
        Apps: this.getItemsData(this.selectedApplications, 'applicationByID'),
        accessories: this.getItemsData(this.selectedAccessories, 'accessoryByID'),
        layout: this.layoutByID(this.selectedLayout),
        fullPrice: this.numberFormatter.format(this.priceFull),
        texts: {
          mTablet: 'MESI mTABLET',
          Apps: this.interfaceData.additionalApps,
          Accessories: this.interfaceData.additionalAccessories,
          layout: this.interfaceData.layout,
          finalPrice: this.interfaceData.yourFinalConfig
        }
      }
      // console.log('PDF data: ', data)
      const postConfig = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        },
        responseType: 'blob'
      }
      return this.axios.post(this.APIURL_PDF, data, postConfig)
        .then((respBlob) => {
          // console.log('SERVER RESPONSE: ', respBlob/* , JSON.stringify(resp.data) */)
          const blob = new Blob([respBlob.data], { type: 'application/pdf' })
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, filename + '.pdf')
          } else {
            const elem = window.document.createElement('a')
            elem.href = window.URL.createObjectURL(blob)
            elem.download = filename + '.pdf'
            document.body.appendChild(elem)
            elem.click()
            document.body.removeChild(elem)
          }
          this.sendMailPDF()
        })
        .catch(err => {
          console.warn('Print PDF error: ', err)
          // this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
        })
    },
    getItemsData (idArray, idFunction) {
      const itemsData = []
      let item = {}
      for (const id of idArray) {
        item = this[idFunction](id)
        if (item.fullPrice !== undefined) {
          item.selectedPrice = this.numberFormatter.format(item.fullPrice)
        }
        if (item.fullPriceExt !== undefined || item.fullPriceSys !== undefined) {
          item.selectedPrice = this.numberFormatter.format(this.ExtModSelectedAsSys === item.id ? item.fullPriceSys : item.fullPriceExt)
        }
        if (item.applications !== undefined && item.applications.included.length > 0) {
          item.includedApps = []
          for (const app of item.applications.included) {
            item.includedApps.push(this.applicationByID(app))
          }
        }
        if (item.accessories !== undefined && item.accessories.included.length > 0) {
          item.includedAccessories = []
          for (const accesory of item.accessories.included) {
            item.includedAccessories.push(this.accessoryByID(accesory))
          }
        }
        itemsData.push(item)
      }
      return itemsData
    },
    showSelectPackageDialog () {
      this.trackEvent('SelectPackageDialogOpen', 'ButtonClick')
      this.$store.commit('SHOW_SELECT_PACKAGE_DIALOG')
    },
    sendInquiryClick () {
      const ourConfigurators = ['MESI', 'fr', 'de', 'es', 'it']
      /* if (this.partnerURL === 'MESI') {
        this.$router.push({ path: '/inquiry-form' })
      } else {
        this.$router.push({ path: '/' + this.partnerURL + '/inquiry-form' })
      } */
      if (ourConfigurators.includes(this.partnerURL)) {
        const extMods = this.selectedExtensionsModules.toString()
        const applications = this.selectedApplications.toString()
        const accessories = this.selectedAccessories.toString()
        const layouts = this.selectedLayout.toString()
        this.trackEvent('SendInquiryCTA', 'ButtonClick')
        window.open(this.FORMURL + '?url=' + encodeURIComponent(this.configURL) + '&extMods=' + extMods + '&apps=' + applications + '&accs=' + accessories + '&layouts=' + layouts, '_blank')
      } else {
        this.$store.commit('SHOW_SEND_INQUIRY_DIALOG')
        this.trackEvent('SendInquiryCTA', 'ButtonClick')
      }
    },
    sendMailPDF () {
      const userData = JSON.parse(this.$localStorage.get('user_data'))

      const requestObject = {
        id: userData !== null ? userData.id : '',
        session_id: userData !== null ? userData.session_id : '',
        new_session: userData !== null && userData.new_session ? userData.new_session : false,
        source: 'PDF',
        partner: this.partnerURL,
        config_url: this.configURL,
        partnerInfo: {
          name: this.partnerURL,
          email: this.interfaceData.contact_email,
          phone: this.interfaceData.contact_phone,
          address: this.interfaceData.contact_address
        },
        customerInfo: {
          user_email: userData !== null ? userData.user_email : 'Shared'
        },
        configuration: this.selectedObject
      }
      // console.log('REQ Object: ', requestObject)

      if (userData !== null) {
        return this.axios.put(this.APIURL_UPDATE, requestObject)
          .then((resp) => {
            // console.warn('SERVER RESPONSE: ', resp)
            if (resp.status) {
              this.$localStorage.set('user_data', JSON.stringify(resp.data))
              // this.$store.dispatch('SHOW_DIALOG_THANK_YOU')
            } /* else {
              this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: resp.data.message })
            } */
          })
          .catch(err => {
            console.warn('sendEmail error: ', err)
            // this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
          })
      } else {
        return this.axios.post(this.APIURL_STORE, requestObject)
          .then((resp) => {
            // console.warn('SERVER RESPONSE: ', resp)
            if (resp.status) {
              this.$localStorage.set('user_data', JSON.stringify(resp.data))
            } /* else {
              this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: resp.data.message })
            } */
          })
          .catch(err => {
            console.warn('sendEmail error: ', err)
            // this.$store.dispatch('SHOW_GENERIC_DIALOG', { text: err.message })
          })
      }
    }
  }
}

</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.price-div {
  display: inline-block;
  position: relative;

  .info-btn {
    top: -1.5rem;
    right: -2rem;
  }
}

.divider-avatar {
  position: relative;

  .m-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

</style>

<style lang="scss">
.ctas-wrap {
  .v-btn__content {
    font-size: 1.2rem;
  }
}
</style>

<!--
This is with a diferent package: domtoimage -> remove it if not using it

/* const tm = setTimeout(() => {
  domtoimage
    .toPng(element)
    .then(function (dataUrl) {
      var img = new Image()
      img.src = dataUrl
      // eslint-disable-next-line new-cap
      const doc = new jsPDF({
        orientation: 'portrait',
        // unit: "pt",
        format: [pdfWidth, pdfHeight]
      })
      doc.addImage(img, 'JPEG', 5, 0, 0, 0, 'SLOW')
      const date = new Date()
      const filename =
      'configuration-' +
      ('0' + date.getDate()).slice(-2) +
      ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
      date.getFullYear() + '-' +
      '.pdf'
      doc.save(filename)
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error)
    })
  clearTimeout(tm)
}, 1000) */
-->
