// import fieldOfCareData from '@/data/fields.json'
import allExtModDataMESI from '@/data/en/extensionsModules.json'
import allAppsDataMESI from '@/data/en/applications.json'
import allLayoutsDataMESI from '@/data/en/layouts.json'
import allAccessoriesDataMESI from '@/data/en/accessories.json'
import interfaceDataJsonMESI from '@/data/en/interfaceData.json'

import allExtModDataMESIfr from '@/data/fr/extensionsModules.json'
import allAppsDataMESIfr from '@/data/fr/applications.json'
import allLayoutsDataMESIfr from '@/data/fr/layouts.json'
import allAccessoriesDataMESIfr from '@/data/fr/accessories.json'
import allConnectivityDatafr from '@/data/fr/connectivity.json'
import interfaceDataJsonMESIfr from '@/data/fr/interfaceData.json'

import allExtModDataMESIde from '@/data/de/extensionsModules.json'
import allAppsDataMESIde from '@/data/de/applications.json'
import allLayoutsDataMESIde from '@/data/de/layouts.json'
import allAccessoriesDataMESIde from '@/data/de/accessories.json'
import interfaceDataJsonMESIde from '@/data/de/interfaceData.json'

import allExtModDataMESIes from '@/data/es/extensionsModules.json'
import allAppsDataMESIes from '@/data/es/applications.json'
import allLayoutsDataMESIes from '@/data/es/layouts.json'
import allAccessoriesDataMESIes from '@/data/es/accessories.json'
import interfaceDataJsonMESIes from '@/data/es/interfaceData.json'

import allExtModDataMESIit from '@/data/it/extensionsModules.json'
import allAppsDataMESIit from '@/data/it/applications.json'
import allLayoutsDataMESIit from '@/data/it/layouts.json'
import allAccessoriesDataMESIit from '@/data/it/accessories.json'
import interfaceDataJsonMESIit from '@/data/it/interfaceData.json'

import allExtModDataMedprax from '@/data/medprax/extensionsModules.json'
import allAppsDataMedprax from '@/data/medprax/applications.json'
import allLayoutsDataMedprax from '@/data/medprax/layouts.json'
import allAccessoriesDataMedprax from '@/data/medprax/accessories.json'
import interfaceDataJsonMedprax from '@/data/medprax/interfaceData.json'

import allExtModDataPartnerTest from '@/data/PartnerTest/extensionsModules.json'
import allAppsDataPartnerTest from '@/data/PartnerTest/applications.json'
import allLayoutsDataPartnerTest from '@/data/PartnerTest/layouts.json'
import allAccessoriesDataPartnerTest from '@/data/PartnerTest/accessories.json'
import interfaceDataJsonPartnerTest from '@/data/PartnerTest/interfaceData.json'

import allExtModDataHiberniamedical from '@/data/hiberniamedical/extensionsModules.json'
import allAppsDataHiberniamedical from '@/data/hiberniamedical/applications.json'
import allLayoutsDataHiberniamedical from '@/data/hiberniamedical/layouts.json'
import allAccessoriesDataHiberniamedical from '@/data/hiberniamedical/accessories.json'
import interfaceDataJsonHiberniamedical from '@/data/hiberniamedical/interfaceData.json'

import allExtModDataBlueMed from '@/data/BlueMed/extensionsModules.json'
import allAppsDataBlueMed from '@/data/BlueMed/applications.json'
import allLayoutsDataBlueMed from '@/data/BlueMed/layouts.json'
import allAccessoriesDataBlueMed from '@/data/BlueMed/accessories.json'
import interfaceDataJsonBlueMed from '@/data/BlueMed/interfaceData.json'

import allExtModDataPromedeus from '@/data/promedeus/extensionsModules.json'
import allAppsDataPromedeus from '@/data/promedeus/applications.json'
import allLayoutsDataPromedeus from '@/data/promedeus/layouts.json'
import allAccessoriesDataPromedeus from '@/data/promedeus/accessories.json'
import interfaceDataJsonPromedeus from '@/data/promedeus/interfaceData.json'

import mixins from '@/mixins.js'

import { getDefaultState } from './state'

const SET_INTERFACE_TEXTS = (state, partner) => {
  if (partner === 'medprax') {
    state.allExtModData = allExtModDataMedprax
    state.allAppsData = allAppsDataMedprax
    state.allLayoutsData = allLayoutsDataMedprax
    state.allAccessoriesData = allAccessoriesDataMedprax
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.partnerLogo = require('@/assets/partnerLogos/medprax-transparent.png')
    state.interfaceData = interfaceDataJsonMedprax
    state.steps = interfaceDataJsonMedprax.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonMedprax.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonMedprax.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'partnerTest') {
    state.allExtModData = allExtModDataPartnerTest
    state.allAppsData = allAppsDataPartnerTest
    state.allLayoutsData = allLayoutsDataPartnerTest
    state.allAccessoriesData = allAccessoriesDataPartnerTest
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.partnerLogo = require('@/assets/partnerLogos/healthy_arteries_logo.png')
    state.interfaceData = interfaceDataJsonPartnerTest
    state.steps = interfaceDataJsonPartnerTest.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonPartnerTest.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonPartnerTest.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'hiberniamedical') {
    state.allExtModData = allExtModDataHiberniamedical
    state.allAppsData = allAppsDataHiberniamedical
    state.allLayoutsData = allLayoutsDataHiberniamedical
    state.allAccessoriesData = allAccessoriesDataHiberniamedical
    state.partnerURL = partner
    state.partnerLogo = require('@/assets/partnerLogos/hiberniamedical-logo.png')
    state.interfaceData = interfaceDataJsonHiberniamedical
    state.steps = interfaceDataJsonHiberniamedical.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonHiberniamedical.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonHiberniamedical.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'bluemed') {
    state.allExtModData = allExtModDataBlueMed
    state.allAppsData = allAppsDataBlueMed
    state.allLayoutsData = allLayoutsDataBlueMed
    state.allAccessoriesData = allAccessoriesDataBlueMed
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.partnerLogo = require('@/assets/partnerLogos/healthy_arteries_logo.png')
    state.interfaceData = interfaceDataJsonBlueMed
    state.steps = interfaceDataJsonBlueMed.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonBlueMed.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonBlueMed.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'promedeus') {
    state.allExtModData = allExtModDataPromedeus
    state.allAppsData = allAppsDataPromedeus
    state.allLayoutsData = allLayoutsDataPromedeus
    state.allAccessoriesData = allAccessoriesDataPromedeus
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.partnerLogo = require('@/assets/partnerLogos/healthy_arteries_logo.png')
    state.interfaceData = interfaceDataJsonPromedeus
    state.steps = interfaceDataJsonPromedeus.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonPromedeus.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonPromedeus.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'fr') {
    state.allExtModData = allExtModDataMESIfr
    state.allAppsData = allAppsDataMESIfr
    state.allLayoutsData = allLayoutsDataMESIfr
    state.allConnectivityData = allConnectivityDatafr
    state.allAccessoriesData = allAccessoriesDataMESIfr
    state.partnerURL = partner
    state.interfaceData = interfaceDataJsonMESIfr
    state.steps = interfaceDataJsonMESIfr.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonMESIfr.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonMESIfr.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'de') {
    state.allExtModData = allExtModDataMESIde
    state.allAppsData = allAppsDataMESIde
    state.allLayoutsData = allLayoutsDataMESIde
    state.allAccessoriesData = allAccessoriesDataMESIde
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.interfaceData = interfaceDataJsonMESIde
    state.steps = interfaceDataJsonMESIde.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonMESIde.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonMESIde.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'es') {
    state.allExtModData = allExtModDataMESIes
    state.allAppsData = allAppsDataMESIes
    state.allLayoutsData = allLayoutsDataMESIes
    state.allAccessoriesData = allAccessoriesDataMESIes
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.interfaceData = interfaceDataJsonMESIes
    state.steps = interfaceDataJsonMESIes.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonMESIes.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonMESIes.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else if (partner === 'it') {
    state.allExtModData = allExtModDataMESIit
    state.allAppsData = allAppsDataMESIit
    state.allLayoutsData = allLayoutsDataMESIit
    state.allAccessoriesData = allAccessoriesDataMESIit
    state.allConnectivityData = allConnectivityDatafr
    state.partnerURL = partner
    state.interfaceData = interfaceDataJsonMESIit
    state.steps = interfaceDataJsonMESIit.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonMESIit.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonMESIit.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  } else {
    state.allExtModData = allExtModDataMESI
    state.allAppsData = allAppsDataMESI
    state.allLayoutsData = allLayoutsDataMESI
    state.allAccessoriesData = allAccessoriesDataMESI
    state.allConnectivityData = allConnectivityDatafr
    state.interfaceData = interfaceDataJsonMESI
    state.steps = interfaceDataJsonMESI.steps
    state.numberFormatter = new Intl.NumberFormat(interfaceDataJsonMESI.languageShortCode, {
      style: 'currency',
      currency: interfaceDataJsonMESI.numberFormat,
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
    })
  }
}

const IS_DISTRIBUTER = (state, payload) => {
  state.isDistributer = payload
}

const REFERRED_BY_DISTRIBUTER = (state, payload) => {
  state.referredByDistributer = payload
}

const PREV_STEP = state => {
  state.activeStep = Math.max(0, state.activeStep - 1)
}

const NEXT_STEP = state => {
  // we dont really need "complete" feature of this do we since moving back should still show
  // which is active, not which is "complete"
  state.steps[state.activeStep].complete = true
  state.activeStep = Math.min(state.steps.length, state.activeStep + 1)
}

const CHANGE_STEP = (state, indx) => {
  if (indx === 'last') {
    state.activeStep = state.steps.length - 1
  } else {
    const st = parseInt(indx)
    if (st && st >= 0 && st < state.steps.length) {
      state.activeStep = indx
    } else {
      state.activeStep = 0
    }
  }
}

/**
 * If you open shared url, complete previous steps automatically
 */
const COMPLETE_STEPS_ON_SHARE = state => {
  for (let i = 0; i < state.steps.length - 1; i++) {
    state.steps[i].complete = true
  }
}

/**
 * If ABI + TBI are selected delete TBI and add TBP
 */
const ADJUST_ABI_TBI = state => {
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, ['TBISYSCS4'])
  state.selected.extensionsModules.push('TBPSYSCS4')
  state.disabled.extensionsModules = mixins.removeItemsFromArray(state.disabled.extensionsModules, ['TBPSYSCS4', 'BPSYSCS4', 'BPSYS'])
  state.disabled.extensionsModules.push('TBISYSCS4')
  state.interfaceData.snackbars.selectionDisabledText = state.interfaceData.snackbars.selectionDisabledTextAbiTbi
  state.interfaceData.snackbars.selectionDisabled = true
}

/**
 * If you go back to selection and TBP is selected, delete TBP and add TBI to have ABI + TBI
 */
const READJUST_ABI_TBI = state => {
  const tbiExtDisabled = ['TBPSYSCS4', 'BPSYSCS4', 'BPSYS']
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, ['TBPSYSCS4'])
  state.selected.extensionsModules.push('TBISYSCS4')
  state.disabled.extensionsModules = mixins.removeItemsFromArray(state.disabled.extensionsModules, ['TBISYSCS4'])
  state.disabled.extensionsModules.push(...tbiExtDisabled)
}

/**
 * If BP + SPO2 + THERMO are selected delete them and select VITALS
 */
const ADJUST_VITALS_PACKAGE = state => {
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, ['BPSYS', 'SPO2SYS', 'THERMOSYSCS4'])
  state.selected.extensionsModules.push('VITALSSYSCS4')
  state.disabled.extensionsModules.push(...['BPSYS', 'SPO2SYS', 'THERMOSYSCS4'])
  state.interfaceData.snackbars.selectionDisabled = true
}

const UPDATE_SELECTED_FIELD = (state, systemId) => {
  state.selectedField = systemId
}

const TOGGLE_EXTENSION_MODULE = (state, moduleID, addOnly = false) => {
  const selectedExtMods = state.selected.extensionsModules.slice()
  const index = selectedExtMods.indexOf(moduleID)
  const add = (index < 0)

  const extMod = state.extensionsModulesByID[moduleID]
  if (typeof extMod === 'undefined') {
    return
  }

  if (add) {
    // console.log('ADD: ' + moduleID)
    state.selected.extensionsModules.push(moduleID)
  } else {
    // console.log('Remove: ' + moduleID)
    if (addOnly) {
      return
    }
    state.selected.extensionsModules.splice(index, 1)
  }
}

const SYNC_DATA = state => {
  // const p1 = performance.now()

  let includedApps = []
  let allowedApps = []

  let includedAccessories = []
  let allowedAccessories = []

  state.fullPriceMTAB = 0
  state.priceFull = 0
  state.price = 0
  state.priceGreenPackage = 0
  state.pricePremiumPackage = 0

  let disabledExtMods = []

  for (const mID of state.selected.extensionsModules) {
    const meObj = state.extensionsModulesByID[mID]

    if (typeof meObj === 'undefined') {
      return
    }

    disabledExtMods = mixins.concatAndRemoveDuplicates(disabledExtMods, meObj.extensionsModules.disabled)
  }

  state.disabled.extensionsModules = disabledExtMods

  // Those that are disabled should not be on selected list now
  const removed = mixins.arrayIntersection(state.selected.extensionsModules, state.disabled.extensionsModules)
  state.selected.extensionsModules = mixins.removeItemsFromArray(state.selected.extensionsModules, state.disabled.extensionsModules)
  if (removed.length > 0) {
    state.dialogs.disabledItemsBySelectingAnother = removed
    state.interfaceData.snackbars.selectionDisabled = true
  }

  for (const mID of state.selected.extensionsModules) {
    const meObj = state.extensionsModulesByID[mID]

    if (typeof meObj === 'undefined') {
      return
    }

    /**
     * FULL PRICE LOGIC
     * Calculate the smallest mTablet price from selected modules and add it to priceFull
     * after you loop currntly selected modules --> So the one with te smallest mTablet price
     * will be the selected "system", the others will be "extentions" --> The difference
     * between sys and ext price will be added with this priceItemMTAB, so when we add
     * prices of selected modules, we add the prices of "extensions" --> meObj.fullPriceExt
     */
    const priceItemMTAB = meObj.fullPriceSys - meObj.fullPriceExt

    if (priceItemMTAB < state.fullPriceMTAB || state.fullPriceMTAB === 0) {
      state.fullPriceMTAB = priceItemMTAB
      state.ExtModSelectedAsSys = meObj.id
    }
    /**
     * UPDATE PRICES (we reset to 0 above then recalculate each time - its easier this way since its
     * just addition - no subtraction. And no need to track prices.
     * Drawback: SLOWER ofcourse since this whole thing runs each time anything is added/removed.
     * Final prices are calculated in a watcher inside PriceDisplay.vue
     */
    const priceFull = typeof meObj.fullPriceExt !== 'undefined' ? parseFloat(meObj.fullPriceExt) : 0
    const price = typeof meObj.subsPriceEur !== 'undefined' ? parseFloat(meObj.subsPriceEur) : 0
    const priceGreen = typeof meObj.subsPriceGreenEur !== 'undefined' ? parseFloat(meObj.subsPriceGreenEur) : 0
    const pricePremium = typeof meObj.subsPricePremiumEur !== 'undefined' ? parseFloat(meObj.subsPricePremiumEur) : 0
    state.priceFull += priceFull
    state.price += price
    state.priceGreenPackage += priceGreen
    state.pricePremiumPackage += pricePremium

    // console.log('Price for ' + mID + ': ' + price)

    const apps = meObj.applications.allowed

    if ((state.selected.extensionsModules.includes('BPSYS') || state.selected.extensionsModules.includes('BPSYSCS4') || state.selected.extensionsModules.includes('ABISYSCS4')) && state.selected.extensionsModules.includes('SPO2SYS')) {
      apps.push('MTABAPPVITALS')
    }

    /**
     * Update preselected and allowed apps/accessories
     */

    includedApps = mixins.concatAndRemoveDuplicates(includedApps, meObj.applications.included)
    includedAccessories = mixins.concatAndRemoveDuplicates(includedAccessories, meObj.accessories.included)

    allowedApps = mixins.concatAndRemoveDuplicates(allowedApps, apps)
    allowedAccessories = mixins.concatAndRemoveDuplicates(allowedAccessories, meObj.accessories.allowed)
  }

  /**
   * Add mTablet full price for current selection
   */
  state.priceFull += state.fullPriceMTAB

  /**
   * Update preselected, selected & allowed APPS
   */
  const keepSelectedApps = mixins.arrayIntersection(state.selected.applications.slice(), allowedApps)
  state.preselected.applications = includedApps
  state.selected.applications = mixins.concatAndRemoveDuplicates(keepSelectedApps, includedApps)
  state.allowed.applications = allowedApps

  /**
   * Update Layouts
   * Enables/disabled TROLLEYECG based on ECGSYSCS4 extension/module
   */
  if (state.selected.extensionsModules.includes('ECGSYSCS4')) {
    state.disabled.layouts = mixins.removeItemsFromArray(state.disabled.layouts, ['TROLLEYECG'])
  } else {
    if (!state.disabled.layouts.includes('TROLLEYECG')) {
      state.disabled.layouts.push('TROLLEYECG')

      // if this was previously selected, select normal TROLLEY instead
      // TODO: alert the user?
      if (state.selected.layout === 'TROLLEYECG') {
        state.selected.layout = 'TROLLEY'
      }
    }
  }

  /**
   * Update preselected, selected & allowed ACCESSORIES
   */
  const keepSelectedAccessories = mixins.arrayIntersection(state.selected.accessories.slice(), allowedAccessories)
  state.preselected.accessories = includedAccessories
  state.selected.accessories = mixins.concatAndRemoveDuplicates(keepSelectedAccessories, includedAccessories)
  state.allowed.accessories = allowedAccessories

  /**
   * Update Connectivity
   */
  state.preselected.connectivity = 'mRECORDS'

  /**
   * UPDATE PRICE based on APPS
   */
  for (const id of state.selected.applications) {
    if (!state.preselected.applications.includes(id)) {
      const item = state.applicationsByID[id]
      const priceFull = typeof item.fullPrice !== 'undefined' ? parseFloat(item.fullPrice) : 0
      const price = typeof item.subsPriceEur !== 'undefined' ? parseFloat(item.subsPriceEur) : 0
      const priceGreen = typeof item.subsPriceGreenEur !== 'undefined' ? parseFloat(item.subsPriceGreenEur) : 0
      const pricePremium = typeof item.subsPricePremiumEur !== 'undefined' ? parseFloat(item.subsPricePremiumEur) : 0
      state.priceFull += priceFull
      state.price += price
      state.priceGreenPackage += priceGreen
      state.pricePremiumPackage += pricePremium
    }
  }

  /**
   * UPDATE PRICE based on ACCESSORIES
   */
  for (const id of state.selected.accessories) {
    if (!state.preselected.accessories.includes(id)) {
      const item = state.accessoriesByID[id]
      const priceFull = typeof item.fullPrice !== 'undefined' ? parseFloat(item.fullPrice) : 0
      const price = typeof item.subsPriceEur !== 'undefined' ? parseFloat(item.subsPriceEur) : 0
      const priceGreen = typeof item.subsPriceGreenEur !== 'undefined' ? parseFloat(item.subsPriceGreenEur) : 0
      const pricePremium = typeof item.subsPricePremiumEur !== 'undefined' ? parseFloat(item.subsPricePremiumEur) : 0
      state.priceFull += priceFull
      state.price += price
      state.priceGreenPackage += priceGreen
      state.pricePremiumPackage += pricePremium
    }
  }

  /**
   * UPDATE PRICE based on CONNECTIVITY --> TODO
   */
  if (state.selected.connectivity.length > 0) {
    const itemCon = state.connectivityByID[state.selected.connectivity[0]]
    console.log('Update priceee:', state.selected.connectivity[0])
    const conPriceFull = typeof itemCon !== 'undefined' ? parseFloat(itemCon.fullPrice) : 0
    state.priceFull += conPriceFull
  }

  // const p2 = performance.now()
  // console.log(p2 - p1 + ' ms')
}

const TOGGLE_APPLICATION = (state, appID, onlyAdd = false) => {
  const selectedApps = state.selected.applications.slice()
  const index = selectedApps.indexOf(appID)
  const add = (index < 0)

  const appObj = state.applicationsByID[appID]
  if (typeof appObj === 'undefined') {
    return
  }

  if (add) {
    state.selected.applications.push(appID)
    // console.log('ADDING app: ', appID)
    if (appObj.noticeText) {
      state.interfaceData.snackbars.generalSnackbarText = appObj.noticeText
      state.interfaceData.snackbars.general = true
    }
  } else {
    if (onlyAdd) {
      return
    }

    state.selected.applications.splice(index, 1)
  }
}

const SET_LAYOUT = (state, layoutID) => {
  const meObj = state.layoutsByID[layoutID]

  state.selected.layout = layoutID
  state.selected.accessories = mixins.removeItemsFromArray(state.selected.accessories, state.preselected.accessoriesActive)
  state.preselected.accessoriesActive = meObj.accessories.included
  state.selected.accessories = mixins.concatAndRemoveDuplicates(state.selected.accessories, meObj.accessories.included)
}

const TOGGLE_ACCESSORY = (state, accID, onlyAdd = false) => {
  const selected = state.selected.accessories.slice()
  const index = selected.indexOf(accID)
  const add = (index < 0)

  const acc = state.accessoriesByID[accID]
  if (typeof acc === 'undefined') {
    return
  }

  if (add) {
    state.selected.accessories.push(accID)
    // console.log('ADDING accessory: ', accID)
  } else {
    if (onlyAdd) {
      return
    }

    state.selected.accessories.splice(index, 1)
  }
}

const SELECT_CONNECTIVITY = (state, connectivity) => {
  state.selected.connectivity.length = 0
  state.selected.connectivity.push(connectivity)
}

const RESET_STATE = state => {
  Object.assign(state, getDefaultState())
}

const SET_CONFIG_CODE = (state, code) => {
  state.configCode = code
}

const SET_CONFIG_URL = (state, url) => {
  state.configURL = url
}

const SET_VISUAL_LINE_HEIGHT = (state, newHeight) => {
  // console.log('SET_VISUAL_LINE_HEIGHT() to ' + newHeight)
  state.visualLineHeight = newHeight
}

const SET_APP_HEIGHT = (state, newHeight) => {
  // console.warn('SET_APP_HEIGHT: ' + newHeight)
  state.appHeight = newHeight
}

/**
 * Needs to be called when changing steps since a step can have a different navigation
 * (last step for example - and also when changing from 4 back to any we have to re-adjust)
 */
const SET_NAV_HEIGHT = (state, newHeight) => {
  state.navigationHeight = newHeight
}

const SHOW_RESET_DIALOG = state => {
  state.dialogs.reset = true
}

const CLOSE_RESET_DIALOG = state => {
  state.dialogs.reset = false
}

const SHOW_LOGIN_DIALOG = state => {
  state.dialogs.loginDialog = true
}

const CLOSE_LOGIN_DIALOG = state => {
  state.dialogs.loginDialog = false
}

const SHOW_SELECT_PACKAGE_DIALOG = state => {
  state.dialogs.selectPackage = true
}

const SHOW_SEND_INQUIRY_DIALOG = state => {
  state.dialogs.sendInquiry = true
}

const SHOW_DIALOG_THANK_YOU = state => {
  state.dialogs.thankYou = true
}

const SHOW_GENERIC_DIALOG = (state, obj) => {
  if (typeof obj.text === 'undefined') {
    return
  }
  state.dialogs.genericDialogContent = obj.text
  state.dialogs.genericDialogLoading = typeof obj.loading !== 'undefined' ? obj.loading : false
  state.dialogs.generic = true
}

const SHOW_SHARE_DIALOG = state => {
  state.dialogs.share = true
}

const CLOSE_SHARE_DIALOG = state => {
  state.dialogs.share = false
}

const CLOSE_GENERIC_DIALOG = state => {
  state.dialogs.generic = false
}

const CLOSE_SELECTION_DISABLED_DIALOG = state => {
  state.dialogs.selectionDisabled = false
}

const CLOSE_ALL_DIALOGS = state => {
  state.genericDialogContent = ''
  state.dialogs.generic = false
  state.dialogs.selectPackage = false
  state.dialogs.sendInquiry = false
  state.dialogs.thankYou = false
}

const REFRESH_VISUAL_BLOCK_KEY = state => {
  // console.warn('REFRESH_VISUAL_BLOCK_KEY')
  state.visualBlockKey = (Date.now() + Math.random())
}

const SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP = (state, marginTopN) => {
  state.visualBlockNegativeMargin = marginTopN
  // console.log('NEW MARGIN TOP Negative: ', marginTopN)
}

const SET_STEP_VISUAL_HEIGHT = (state, obj) => {
  if (typeof obj.stepIndx === 'undefined' || typeof obj.height === 'undefined') {
    return
  }
  // console.log('set step ', obj.stepIndx, ' in vuex visual height to ', obj.height)
  state.steps[obj.stepIndx].visualStepHeight = obj.height
}

const SET_PRICE_FINAL = (state, price) => {
  state.pricesAfterMtab.price = price
}

const SET_PRICE_GREEN_FINAL = (state, price) => {
  state.pricesAfterMtab.priceGreenPackage = price
}

const SET_PRICE_PREMIUM_FINAL = (state, price) => {
  state.pricesAfterMtab.pricePremiumPackage = price
}

const SET_SELECTED = (state, selectedObject) => {
  // console.log(' - - - - - update SELECTED: ', selectedObject)
  Object.assign(state.selected, selectedObject)
}

const SET_PRESELECTED = (state, preselectedObject) => {
  // console.log(' - - - - - update PRE-SELECTED: ', preselectedObject)
  Object.assign(state.preselected, preselectedObject)
}

/**
 * So we can access object data by ID easily across the app
 */
const GENERATE_BY_ID_OBJECTS = state => {
  for (const obj of state.allExtModData) {
    state.extensionsModulesByID[obj.id] = obj
  }

  for (const obj of state.allAppsData) {
    state.applicationsByID[obj.id] = obj
  }

  for (const obj of state.allLayoutsData) {
    state.layoutsByID[obj.id] = obj
  }

  for (const obj of state.allAccessoriesData) {
    state.accessoriesByID[obj.id] = obj
  }

  for (const obj of state.allConnectivityData) {
    state.connectivityByID[obj.id] = obj
  }
}

const SET_DEFAULT_MTAB_PRICE = state => {
  if (state.extensionsModulesByID.MTABSYSW) {
    state.defaultPriceMTAB = state.extensionsModulesByID.MTABSYSW.subsPriceEur
  }
}

const HIDE_SELECTION_DISABLED_SNACKBAR = state => {
  state.interfaceData.snackbars.selectionDisabled = false
  state.interfaceData.snackbars.selectionDisabledText = state.interfaceData.snackbars.selectionDisabledTextOriginal
}

const HIDE_GENERAL_SNACKBAR = state => {
  state.interfaceData.snackbars.general = false
}

const SET_VISUAL_BLOCK_VISIBLE_MOBILE = (state, visible) => {
  state.visualBlockVisibleMobile = visible
}

const MODIFY_SELECTED_EXT_MODS = (state, payload) => {
  state.modifiedSelectedExtMods = payload
}

export default {
  SET_INTERFACE_TEXTS,
  IS_DISTRIBUTER,
  REFERRED_BY_DISTRIBUTER,
  PREV_STEP,
  NEXT_STEP,
  CHANGE_STEP,
  COMPLETE_STEPS_ON_SHARE,
  ADJUST_ABI_TBI,
  READJUST_ABI_TBI,
  ADJUST_VITALS_PACKAGE,
  SET_CONFIG_CODE,
  SET_CONFIG_URL,
  UPDATE_SELECTED_FIELD,
  TOGGLE_EXTENSION_MODULE,
  SYNC_DATA,
  TOGGLE_APPLICATION,
  SET_LAYOUT,
  TOGGLE_ACCESSORY,
  SELECT_CONNECTIVITY,
  RESET_STATE,
  SET_VISUAL_LINE_HEIGHT,
  SET_APP_HEIGHT,
  SET_NAV_HEIGHT,
  SHOW_RESET_DIALOG,
  CLOSE_RESET_DIALOG,
  SHOW_LOGIN_DIALOG,
  CLOSE_LOGIN_DIALOG,
  SHOW_SELECT_PACKAGE_DIALOG,
  SHOW_SEND_INQUIRY_DIALOG,
  SHOW_DIALOG_THANK_YOU,
  SHOW_GENERIC_DIALOG,
  CLOSE_GENERIC_DIALOG,
  SHOW_SHARE_DIALOG,
  CLOSE_SHARE_DIALOG,
  CLOSE_SELECTION_DISABLED_DIALOG,
  CLOSE_ALL_DIALOGS,
  REFRESH_VISUAL_BLOCK_KEY,
  SET_VISUAL_BLOCK_NEGATIVE_MARGIN_TOP,
  SET_STEP_VISUAL_HEIGHT,
  SET_PRICE_FINAL,
  SET_PRICE_GREEN_FINAL,
  SET_PRICE_PREMIUM_FINAL,
  SET_SELECTED,
  SET_PRESELECTED,
  GENERATE_BY_ID_OBJECTS,
  SET_DEFAULT_MTAB_PRICE,
  HIDE_SELECTION_DISABLED_SNACKBAR,
  HIDE_GENERAL_SNACKBAR,
  SET_VISUAL_BLOCK_VISIBLE_MOBILE,
  MODIFY_SELECTED_EXT_MODS
}
