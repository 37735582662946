<template>
  <div>
    <sectionTitle>
      {{ interfaceData.connectivityText.title }}
      <template v-slot:subtitle>
        {{ interfaceData.connectivityText.subtitle }}
      </template>
    </sectionTitle>
    <div class="pb-12">
      <v-row v-if="preselectedConn !== undefined" class="mb-5">
        <v-col
          class="item d-flex child-flex justify-space-between"
          cols="7"
          justify-space-between
        >
          <div>
            {{ preselectedConn.name }}<br />
            <span class="font-weight-light caption">{{ preselectedConn.subtext }}</span>
          </div>
          <v-switch
            v-model="preselectedConn.id"
            class="px-4 my-auto"
            inset
            data-cy-con-item
            color="accent"
            hide-details
            value="mRECORDS"
            disabled
          >
          </v-switch>
        </v-col>
      </v-row>
      <hr>
      <v-row v-if="preselectedConn !== undefined" class="d-block">
        <div
          v-for="(con, k) in filteredConnectivity"
          :key="k"
        >
          <hr v-if="k === filteredConnectivity.length-1" class="mb-5">
          <v-col
            class="item d-flex child-flex justify-space-between"
            :class="itemClass(con.id)"
            sm="7"
            justify-space-between
          >
            <div>
              <v-img
                v-if="con.id !== 'Custom'"
                :src="imgPath(con.img)"
                class="mx-auto img-connectivity"
                :alt="con.name"
                :lazy-src="lazyImgSrc"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <div v-if="con.id === 'Custom'">
              {{ con.name }}<br />
              <span class="font-weight-light caption">{{ con.subtext }}</span>
            </div>
            <v-switch
              @change="toggleConnectivity(con.id)"
              v-model="selected"
              class="px-4 my-auto"
              inset
              data-cy-con-item
              color="accent"
              hide-details
              :value="con.id">
            </v-switch>
          </v-col>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import sectionTitle from '@/components/sectionTitle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Conectivities',
  props: {
    systemData: Object,
    fieldOfCareData: Array
  },
  components: {
    sectionTitle
  },
  created () {
    this.toggleConnectivity = this.debounce(this.toggleConnectivity)
  },
  computed: {
    ...mapGetters([
      'interfaceData',
      'allConnectivityData',
      'steps',
      'preselectedConnectivity'
    ]),
    lazyImgSrc () {
      return require('@/assets/placeholder.jpg')
    },
    filteredConnectivity () {
      return this.allConnectivityData.filter(conn => {
        return !this.isPreselected(conn.id)
      })
    },
    preselectedConn () {
      return this.allConnectivityData.filter(connectivity => {
        return this.isPreselected(connectivity.id)
      })[0]
    },
    selected: {
      get () {
        return this.$store.getters.selectedConnectivity
      },
      set (newSelected) {
        // this.toggleConnectivity(newSelected)
      }
    }
  },
  methods: {
    toggleConnectivity (conID = -1) {
      this.$store.dispatch('TOGGLE_CONNECTIVITY', conID)
    },
    isPreselected (id) {
      return this.preselectedConnectivity.includes(id)
    },
    itemClass (id) {
      let classes = ''
      if (this.isPreselected(id)) {
        classes += 'disabled'
      }
      return classes
    },
    imgPath: path => require('@/assets/connectivity/interactive/' + path)
  }
}
</script>

<style lang="scss" scoped>
.item {
  &.disabled {
    opacity: 0.4;
  }
}

.v-input--switch.v-input--is-dirty.v-input--is-disabled {
  opacity: .5;
}

.img-connectivity {
  width: 150px;
}
</style>
