
const partnerURL = state => state.partnerURL
const partnerLogo = state => state.partnerLogo
const isDistributer = state => state.isDistributer
const referredByDistributer = state => state.referredByDistributer
const interfaceData = state => state.interfaceData
const allExtModData = state => state.allExtModData
const allAppsData = state => state.allAppsData
const allLayoutsData = state => state.allLayoutsData
const allAccessoriesData = state => state.allAccessoriesData
const allConnectivityData = state => state.allConnectivityData
const activeStep = state => state.activeStep
const steps = state => state.steps
const isLastStep = state => state.activeStep === (state.steps.length - 1)

/**
 * Price that is not displayed, but is added to the sum of
 * all the prices defined for each package.
 */
const defaultPriceMTAB = state => state.defaultPriceMTAB
const fullPriceMTAB = state => state.fullPriceMTAB

const ExtModSelectedAsSys = state => state.ExtModSelectedAsSys

const priceFull = state => state.priceFull
const price = state => state.price
const priceGreenPackage = state => state.priceGreenPackage
const pricePremiumPackage = state => state.pricePremiumPackage

const priceFinal = state => state.pricesAfterMtab.price
const priceFinalGreen = state => state.pricesAfterMtab.priceGreenPackage
const priceFinalPremium = state => state.pricesAfterMtab.pricePremiumPackage
const numberFormatter = state => state.numberFormatter

const selectedField = state => state.selectedField

const selectedObject = state => state.selected

const selectedExtensionsModules = state => state.selected.extensionsModules
const disabledExtModules = state => state.disabled.extensionsModules
const modifiedSelectedExtMods = state => state.modifiedSelectedExtMods

const preselectedApplications = state => state.preselected.applications
const preselectedAccessories = state => state.preselected.accessories
const preselectedConnectivity = state => state.preselected.connectivity

const selectedApplications = state => state.selected.applications
const selectedAccessories = state => state.selected.accessories
const selectedConnectivity = state => state.selected.connectivity

const selectedLayout = state => state.selected.layout
const disabledLayouts = state => state.disabled.layouts

const allowedApplications = state => state.allowed.applications
const allowedAccessories = state => state.allowed.accessories

const visualLineHeight = state => state.visualLineHeight

const appHeight = state => state.appHeight
const navigationHeight = state => state.navigationHeight

const isLoginDialogVisible = state => state.dialogs.loginDialog
const isSelectPackageDialogVisible = state => state.dialogs.selectPackage
const isSendInquiryDialogVisible = state => state.dialogs.sendInquiry
const isThankYouDialogVisible = state => state.dialogs.thankYou
const isGenericDialogVisible = state => state.dialogs.generic
const isResetDialogVisible = state => state.dialogs.reset
const isShareDialogVisible = state => state.dialogs.share
const isSelectionDisabledDialogVisible = state => state.dialogs.selectionDisabled
const disabledItemsBySelectingAnother = state => state.dialogs.disabledItemsBySelectingAnother

const selectionDisabledSnackbarVisible = state => state.interfaceData.snackbars.selectionDisabled
const selectionDisabledText = state => state.interfaceData.snackbars.selectionDisabledText
const generalSnackbarText = state => state.interfaceData.snackbars.generalSnackbarText
const generalSnackbarVisible = state => state.interfaceData.snackbars.general

const genericDialogContent = state => state.dialogs.genericDialogContent
const genericDialogLoading = state => state.dialogs.genericDialogLoading

const visualBlockKey = state => state.visualBlockKey
const visualBlockNegativeMargin = state => state.visualBlockNegativeMargin

const getStepVisualHeight = state => (stepIndx = 0) => {
  if (typeof state.steps[stepIndx] === 'undefined') {
    return
  }
  return state.steps[stepIndx].visualStepHeight
}

const configCode = state => state.configCode
const configURL = state => state.configURL
const configCodeSeparator = state => state.configCodeSeparator

const extModByID = (state) => (id) => {
  if (typeof state.extensionsModulesByID[id] === 'undefined') {
    return ''
  }
  return state.extensionsModulesByID[id]
}

const applicationByID = (state) => (id) => {
  if (typeof state.applicationsByID[id] === 'undefined') {
    return ''
  }
  return state.applicationsByID[id]
}

const layoutByID = (state) => (id) => {
  if (typeof state.layoutsByID[id] === 'undefined') {
    return ''
  }
  return state.layoutsByID[id]
}

const accessoryByID = (state) => (id) => {
  if (typeof state.accessoriesByID[id] === 'undefined') {
    return ''
  }
  return state.accessoriesByID[id]
}
const connectivityByID = (state) => (id) => {
  if (typeof state.connectivityByID[id] === 'undefined') {
    return {}
  }
  return state.connectivityByID[id]
}

const APIURL_REGISTER = state => state.APIURL_REGISTER
const APIURL_REFERAL = state => state.APIURL_REFERAL
const APIURL_LOGIN = state => state.APIURL_LOGIN
const APIURL_STORE = state => state.APIURL_STORE
const APIURL_UPDATE = state => state.APIURL_UPDATE
const APIURL_PDF = state => state.APIURL_PDF
const FORMURL = state => state.FORMURL
const FORMURL_SUBSCRIPTION = state => state.FORMURL_SUBSCRIPTION

const mTabIncludedApps = state => state.mTabIncludedApps

const visualBlockVisibleMobile = state => state.visualBlockVisibleMobile

const extModIsSelected = (state) => {
  return state.selected.extensionsModules.length > 0
}

export default {
  partnerURL,
  partnerLogo,
  isDistributer,
  referredByDistributer,
  interfaceData,
  allExtModData,
  allAppsData,
  allLayoutsData,
  allConnectivityData,
  allAccessoriesData,
  activeStep,
  visualLineHeight,
  navigationHeight,
  appHeight,
  steps,
  isLastStep,
  defaultPriceMTAB,
  fullPriceMTAB,
  ExtModSelectedAsSys,
  priceFull,
  price,
  priceGreenPackage,
  pricePremiumPackage,
  priceFinal,
  priceFinalGreen,
  priceFinalPremium,
  numberFormatter,
  selectedObject,
  selectedField,
  selectedExtensionsModules,
  disabledExtModules,
  modifiedSelectedExtMods,
  selectedApplications,
  allowedApplications,
  preselectedApplications,
  selectedLayout,
  disabledLayouts,
  selectedAccessories,
  preselectedAccessories,
  preselectedConnectivity,
  selectedConnectivity,
  allowedAccessories,
  isLoginDialogVisible,
  isSelectPackageDialogVisible,
  isSendInquiryDialogVisible,
  isThankYouDialogVisible,
  isGenericDialogVisible,
  isResetDialogVisible,
  isShareDialogVisible,
  isSelectionDisabledDialogVisible,
  selectionDisabledSnackbarVisible,
  selectionDisabledText,
  generalSnackbarText,
  generalSnackbarVisible,
  disabledItemsBySelectingAnother,
  genericDialogContent,
  genericDialogLoading,
  visualBlockKey,
  visualBlockNegativeMargin,
  getStepVisualHeight,
  configCode,
  configURL,
  configCodeSeparator,
  extModByID,
  applicationByID,
  layoutByID,
  accessoryByID,
  connectivityByID,
  APIURL_REGISTER,
  APIURL_REFERAL,
  APIURL_LOGIN,
  APIURL_STORE,
  APIURL_UPDATE,
  APIURL_PDF,
  FORMURL,
  FORMURL_SUBSCRIPTION,
  mTabIncludedApps,
  visualBlockVisibleMobile,
  extModIsSelected
}
